.wrapper {
    height: calc(100vh - 100px);
    padding: 20px 0;
    overflow: hidden;
    overflow-y: auto;
}

.error {
    color: red;
    font-size: 12px;
    line-height: 1;
    margin-top: 5px;
    margin-bottom: 0;
}

.error p {
    font-size: 12px;
    margin-bottom: 0;
}

.editArea {
    display: flex;
    justify-content: center;
    align-items: center;
}

.editArea .item {
    margin-right: 5px;
    cursor: pointer;
}

.editArea .item:last-child {
    margin-right: 0;
}

.noActiveStt,
.activeStt {
    font-weight: 600;
}

.noActiveStt {
    color: red;
}

.activeStt {
    color: green;
}

.editHeaderArea {
    width: 78px;
}

.treeParent {
    font-size: .9rem !important;
    font-weight: 700;
}

.treeChild {
    font-size: .8rem !important;
}

.treeChild.childs {
    color: #09b2fd;
    font-weight: 600;
}

.treeChild.childsItem {
    font-weight: 500;
}

.childLine {
    font-size: 1rem;
}

.formControl {
    display: flex;
    justify-content: center;
    align-items: center;
    flex-direction: column;
}

.formControl .rowItem {
    display: flex;
    width: 100%;
    margin-bottom: 10px;
    justify-content: center;
}

.formControl .rowItem .inputArea {
    width: 100%;
}

.formControl .rowItem label {
    display: flex;
    width: 200px;
    height: 48px;
    margin-bottom: 0;
    align-items: center;
}

.formControl .rowTitle {
    width: 100%;
}

.table-hover-css:hover {
    background-color: rgba(0, 0, 0, .075);
}