#history .rdtPicker {
    top: 50px;
    right: 0;
    width: 265px;
}

#history .rdtPicker:before,
#history .rdtPicker:after {
    right: 10px;
    left: auto;
}

.table-css-history .thead-dark th:nth-child(2) {
    width: 170px;
}

.table-css-history .thead-dark th:nth-child(3) {
    width: 250px;
}

/* .table-css-history .thead-dark th:nth-child(4) {
    width: 500px;
} */

.table-hover-css:hover {
    background-color: rgba(0, 0, 0, .075);
}

.form-group {
    margin-bottom: 0px !important;
}

.div_flex {
    display: flex;
}

.mg-div-search {
    margin: 0 15px;
}

.mg-div-search:first-child {
    margin-left: 0px;
}

.mg-div-search:last-child {
    margin-right: 0px;
}



@media screen and (min-width: 360px) and (max-width: 750px) {
    .div_flex {
        display: block;
        width: 100%;
    }

    .mg-div-search {
        margin: 0;
    }

    /* .mmg-btn button{
        ma
    } */

}