.headerArea {
    height: 50px;
}

.boxTitle {
    font-size: 1.1rem;
    font-weight: 600;
    font-style: italic;
    line-height: 1.3;
    margin-bottom: 10px!important;
}