.select {
    cursor: pointer;
    display: block;
    position: relative;
    width: 100%;
    height: calc(1.5em + 1.25rem + 2px);
    padding: 0.625rem 0.75rem;
    font-size: 0.875rem;
    font-weight: 400;
    line-height: 1.5;
    color: #000000;
    background-color: #fff;
    background-clip: padding-box;
    border: 1px solid #cad1d7;
    border-radius: 0.375rem;
    box-shadow: none;
    transition: all 0.2s cubic-bezier(0.68, -0.55, 0.265, 1.55);
}

.selectHeader {
    display: flex;
    justify-content: space-between;
    align-items: center;
    cursor: pointer;
}

.selectHeader .text {
    overflow: hidden;
    white-space: nowrap;    
    text-overflow: ellipsis;
}

.selectBody {
    position: absolute;
    width: 100%;
    max-height: 200px;
    top: 100%;
    left: 0;
    padding: 10px;
    background: white;
    overflow: hidden;
    overflow-y: auto;   
    box-shadow: 1px 1px 8px 1px grey;
    -webkit-box-shadow: 1px 1px 8px 1px grey;
    -moz-box-shadow: 1px 1px 8px 1px grey;
    -ms-box-shadow: 1px 1px 8px 1px grey;
    -o-box-shadow: 1px 1px 8px 1px grey;
    border-radius: 4px;
    -webkit-border-radius: 4px;
    -moz-border-radius: 4px;
    -ms-border-radius: 4px;
    -o-border-radius: 4px;
    transform: translateY(4px);
    -moz-transform: translateY(4px);
    -webkit-transform: translateY(4px);
    -ms-transform: translateY(4px);
    -o-transform: translateY(4px);
    cursor: pointer;
    z-index: 1;
}

.selectBody .items {
    display: flex;
    padding: 5px;
    justify-content: flex-start;
    align-items: center;
}

.selectBody .items:first-child {
    border-bottom: 1px solid #f1f1f1;
}

.selectBody .items:last-child {
    border-bottom: none;
}

.selectBody .text {
    width: 100%;
    padding-left: 5px;
    overflow: hidden;
    white-space: nowrap;    
    text-overflow: ellipsis;
    pointer-events: none;
}

.treeParent {
    font-size: .9rem!important;
    font-weight: 700;
}

.treeChild {
    font-size: .8rem!important;
}

.treeChild.childs {
    color: #09b2fd;
    font-weight: 600;
}

.treeChild.childsItem {
    font-weight: 500;
}

.childLine {
    font-size: 1rem;
}