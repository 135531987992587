.wrapper {
  height: calc(100vh - 100px);
  padding: 20px 0;
  overflow: hidden;
  overflow-y: auto;
}

.error {
  color: red;
  font-size: 12px;
  line-height: 1;
  margin-top: 5px;
  margin-bottom: 0;
}

.editArea {
  display: flex;
  justify-content: center;
  align-items: center;
}

.editArea .item {
  margin-right: 5px;
  cursor: pointer;
}

.editArea .item:last-child {
  margin-right: 0;
}

.moitao {
  display: block;
  width: 7.5rem;
  margin: auto;
  color: #000;
  padding: 3px 0;
  font-weight: 600;
  border-radius: 40px;
}

.daduyet {
  display: block;
  width: 7.5rem;
  margin: auto;
  color: orange;
  padding: 3px 0;
  font-weight: 600;
  border-radius: 40px;
}

.khongduyet {
  display: block;
  width: 7.5rem;
  margin: auto;
  color: #04bf16;
  padding: 3px 0;
  font-weight: 600;
  border-radius: 40px;
}

.yeucauduyet {
  display: block;
  width: 7.5rem;
  margin: auto;
  color: #fff;
  padding: 3px 0;
  font-weight: 600;
  border-radius: 40px;
}

.khongduyet {
  background-color: #fff;
  border: 1px solid #04bf16;
}

.yeucauduyet {
  background-color: #09b2fd;
}

.moitao {
  background-color: #fff;
  border: 1px solid #000;
}

.daduyet {
  background-color: #fff;
  border: 1px solid orange;
}

.editHeaderArea {
  width: 78px;
}

.searchArea {
  display: flex;
  width: 100%;
  justify-content: center;
  align-items: center;
  flex-direction: column;
}

.searchArea .searchRow {
  display: flex;
  width: 100%;
  justify-content: flex-start;
  align-items: center;
  margin-bottom: 5px;
}

.searchArea .searchRow:last-child {
  margin-bottom: 0;
}

.searchArea .searchRow .checkBoxItem {
  margin-right: 15px;
}

.searchArea .searchRow .checkBoxItem input[type="checkbox"] {
  margin-right: 10px;
  transform: scale(1.2);
  -webkit-transform: scale(1.2);
  -moz-transform: scale(1.2);
  -ms-transform: scale(1.2);
  -o-transform: scale(1.2);
}

.searchArea .searchRow .searchRowItem {
  width: 100%;
}

.formControl {
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
}

.formControl .rowItem {
  display: flex;
  width: 100%;
  margin-bottom: 10px;
  justify-content: center;
  align-items: flex-start;
}

.formControl .rowItem .inputArea {
  width: 100%;
}

.formControl .rowItem label {
  display: flex;
  width: 160px;
  height: 43px;
  margin-bottom: 0;
  align-items: center;
}

.formControl .rowTitle {
  width: 100%;
}

.filterArea {
  display: flex;
  justify-content: flex-start;
  align-items: center;
}

.filterArea label {
  width: 90px;
  margin-bottom: 0;
}

.filterArea select {
  width: 300px;
  margin-right: 8px;
  height: 2.2em;
  padding-top: 0;
  padding-bottom: 0;
}

.headView {
  padding-bottom: 5px;
  margin-bottom: 5px;
  border-bottom: 1px solid #000;
}

@media screen and (max-width: 991px) {
  .filterArea select {
    width: 200px;
  }
}

@media screen and (max-width: 568px) {
  .filterArea {
    width: 100%;
    margin-bottom: 10px;
    flex-direction: column;
    align-items: flex-start;
  }

  .filterArea select {
    width: 100%;
  }
}

@media screen and (max-width: 359px) {
  .searchArea .searchRow .checkBoxItem input[type="checkbox"] {
    margin-right: 8px;
    transform: scale(1);
    -webkit-transform: scale(1);
    -moz-transform: scale(1);
    -ms-transform: scale(1);
    -o-transform: scale(1);
  }

  .searchArea .searchRow .checkBoxItem label {
    font-size: 0.8rem;
  }
}

/* *********************** */
/* Adding for table fields */
/* *********************** */
.content_main {
	display: flex;
	height: 800px;
	/*background-color: #04bf16;*/
}

/* Menu list */
.content_menu_list {
	width: 20%;
	background-color: #F0FFFF;
	margin-right: 5px;
	height: 600px;
  padding: 10px;
  box-shadow: 0 4px 6px -1px rgb(0 0 0 / 0.1), 0 2px 4px -2px rgb(0 0 0 / 0.1);
}

.content_menu_list label {
  font-size: 20px;
}

.form_menu_list {
	display: flex;
	flex-direction: column;
	width: 100%;
	height: 100%;
}

.rp_components {
	display: flex;
	width: 100%;
	height: 8%;
	/* background-color: green; */
}

.list_of_title {
	display: flex;
	justify-content: start;
	align-items: center;
	width: 85%;
	height: 100%;
	/* background-color: yellow; */
}

.list_of_checkbox {
	display: flex;
	justify-content: center;
	align-items: center;
	width: 15%;
	height: 100%;
	/* background-color: blue; */
}
/* End list */

.content_table_views {
	width: 80%;
	background-color: #FFFAF0;
	margin-left: 5px;
  padding: 10px;
  box-shadow: 0 4px 6px -1px rgb(0 0 0 / 0.1), 0 2px 4px -2px rgb(0 0 0 / 0.1);
}

.content_table_views label {
  font-size: 20px;
}

.report_table_views {
	width: 100%;
	height: 100%;
	/* background-color: gray; */
}

.options_fields {
	display: flex;
	width: 100%;
	height: 8%;
	/* background-color: #04bf16; */
}

.options_name_fields {
	display: flex;
	flex-direction: row;
	justify-content: center;
	align-items: center;
	width: 50%;
	height: 100%;
	/* background-color: yellow; */
}

.options_name_fields p {
	margin-top: 50;
}

.input_name_fields {
	margin-left: 20px;
	margin-right: 20px;
}

.options_button_fields {
	display: flex;
	justify-content: center;
	align-items: center;
	width: 50%;
	height: 100%;
	/* background-color: blue; */
}

.elements_in_options {
	margin-left: 10px;
}

.select_options {
	margin-left: 10px;
	height: 32px;
}

/* Table fields */
.table_fields {
	width: 100%;
	height: 100%;
	/* background-color: blue; */
}
/* End table */

/* Responsive of form */
@media screen and (max-width: 1024px) {
	.content_main {
		display: flex;
		flex-direction: column;
	}

	.content_menu_list {
		width: 100%;
		height: 400px;
		margin: 0;
	}

	.content_table_views {
		width: 100%;
		height: 100%;
		margin: 0;
	}

	.rp_components {
		display: flex;
		width: 100%;
		height: 15%;
		/* background-color: green; */
	}
}
/* End responsive */
/* End adding */
