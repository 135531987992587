table {
    display: block;
    position: relative;
    max-height: 70vh;
    margin-bottom: 1px!important;
    overflow-y: scroll;
}

table thead th {
    position: sticky;
    width: 100vw;
    top: -1px;
    text-align: center;
    color: white!important;
    border: 1px solid #d1d1d1!important;
}

table tbody td {
    text-align: center;
    border: 1px solid #d1d1d1!important;
}

.editHeaderArea {
    width: 50px!important;
}

.indexTitle {
    width: 78px!important;
}